import React from 'react';

const EmptyState = ({
  Icon = null,
  mainMessage = 'No available content',
  subMessage = "We'll let you know when we got new content for you.",
}) => {
  return (
    <div className="article-cards-viewer-empty">
      {Icon}
      <h1 className="empty-main-message">{mainMessage}</h1>
      <h4 className="empty-sub-message">{subMessage}</h4>
    </div>
  );
};

export default EmptyState;
