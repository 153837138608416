import React from 'react';
import { useQueryParams } from 'use-query-params';
import SEO from '@components/SEO';
import Layout from '@components/Layout/LayoutTemplates/CareerDevelopmentLayout';
import ResourcesEmptyState from './ResourceEmptyState';
import ArticleCardsViewer from '@components/ArticleCardsViewer';
import MainPageSearch from '@components/SearchWithCTA/MainPageSearch';
import filterContents from '@helpers/methods/filterContents';
import { DEFAULT_URL_QUERY } from '@helpers/methods/filterContents/constants';
import EmptyPage from '@components/EmptyState/EmptyPage';
import DataWrapper from '@components/DataWrapper';

const Resources = ({ data }) => {
  const {
    allResources: contents,
    flexSearchOptions,
    searchSuggestions,
    hasNoContent,
  } = data;

  const [filters, setFilters] = useQueryParams(DEFAULT_URL_QUERY);

  const handleSearchQuery = (response) => {
    const { results, query } = response;

    const isQueryInvalid = typeof query !== 'string';
    if (isQueryInvalid) return;

    const hasSameQueryAsBefore = filters.query === query;
    if (hasSameQueryAsBefore) return;

    const sanitizeQuery = (queryString) => {
      const isQueryEmptyString = queryString === '';
      return isQueryEmptyString ? undefined : queryString;
    };

    setFilters({
      search: results,
      query: sanitizeQuery(query),
    });
  };

  const visibleContents = filterContents(contents, filters);
  return (
    <Layout className={'resources-container'}>
      <SEO title="Career Advice Results" description="Results" />

      <DataWrapper
        isEmpty={hasNoContent}
        empty={
          <EmptyPage
            iconHref={'file-icon'}
            mainMessage={'No available resources'}
            subMessage={"We'll let you know when we got resources for you."}
          />
        }
      >
        <MainPageSearch
          header={'Resources from Wan PNG community'}
          subHeader={
            'Free resources to help you learn and accelerate your career.'
          }
          searchPlaceholder={'Search from our resources'}
          defaultValue={filters.query}
          onQuery={handleSearchQuery}
          flexSearchOptions={flexSearchOptions}
          searchSuggestions={searchSuggestions}
        />

        <div className="resources-results-preview-container _section-padding">
          <ArticleCardsViewer
            EmptyState={<ResourcesEmptyState />}
            contents={visibleContents}
          />
        </div>
      </DataWrapper>
    </Layout>
  );
};

export default Resources;
