import React from 'react';
import Button from 'antd/lib/button';
import classNames from 'classnames';

const LoadMoreButton = ({ onClick, isVisible = true }) => {
  return (
    <div className={classNames('load-more-button', { _invisible: !isVisible })}>
      <Button onClick={onClick}>Load more</Button>
    </div>
  );
};

export default LoadMoreButton;
