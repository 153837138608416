import { useStaticQuery, graphql } from 'gatsby';
import isEmpty from 'lodash/isEmpty';
import useFlexSuggestionIndex from '@helpers/hooks/useFlexSuggestionIndex';
import getResourceCardsProps from '@helpers/methods/modifyContents/getResourceCardsProps';

const GET_RESOURCES = graphql`
  {
    allStrapiResources {
      nodes {
        id
        title
        slug
        creator_id
        author: created_by {
          username
        }

        thumbnail_image {
          source_image {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, quality: 100)
              }
            }
            url
          }
          cropped_image {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, quality: 100)
              }
            }
            url
          }
        }
        thumbnail_image_old: thumbnail {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, quality: 100)
            }
          }
          url
        }
      }
    }

    flexSearchOptions: localSearchResources {
      index
      store
    }
    flexSuggestionOptions: localSearchResourcesSuggestions {
      index
    }
  }
`;

const Fetcher = ({ children }) => {
  const {
    allStrapiResources,
    flexSearchOptions,
    flexSuggestionOptions,
  } = useStaticQuery(GET_RESOURCES);

  const allResources = getResourceCardsProps(allStrapiResources?.nodes);

  const hasNoContent = isEmpty(allStrapiResources?.nodes);

  const suggestedWordsIndex = useFlexSuggestionIndex(
    flexSuggestionOptions?.index
  );
  const suggestedTitlesIndex = allResources.map(({ title }) => title);
  const searchSuggestions = [...suggestedWordsIndex, ...suggestedTitlesIndex];

  return children({
    allResources,
    flexSearchOptions,
    searchSuggestions,
    hasNoContent,
  });
};

export default Fetcher;
