import React from 'react';

const EventsPageEmptyState = ({
  iconHref = '',
  mainMessage = '',
  subMessage = '',
}) => {
  return (
    <div className="article-page-empty-state">
      <div className="empty-search-icon-container">
        <svg className="empty-search-icon">
          <use xlinkHref={`#${iconHref}`}></use>
        </svg>
      </div>
      <h1 className="empty-main-message">{mainMessage}</h1>
      <h4 className="empty-sub-message">{subMessage}</h4>
    </div>
  );
};

export default EventsPageEmptyState;
